.bg {
    position: absolute;
    top: 0;
    left: 0;
    width: 99vw;
    height: 100vh;
    background-size: cover;
    background-position: center;
    will-change: opacity;
    z-index: -1;
}