@tailwind base;
@tailwind components;
@tailwind utilities;

.ant-btn-primary {
  background-color: #023047 !important;
  border-color: #023047 !important;
}

.ant-btn-primary:hover {
  background-color: #0366d6 !important; /* Changez cette couleur par la couleur que vous voulez au survol */
  border-color: #0366d6 !important; /* Changez cette couleur par la couleur que vous voulez au survol */
}

.ant-switch-inner-checked {
  background-color: #023047 !important;
}

.ant-switch:not(.ant-switch-checked) {
  background-color: #ff9843 !important;
}
